






















import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {calendarStoreGetter, calendarStoreMutations} from '@/store/calendar.store';
import CalendarItemIsSelectedDefinition from '@/interfaces/CalendarItemBorderDefinition.interface';
import {CALENDAR_ITEM_WIDTH} from '@/components/calendar/misc/calendar.config';
import Slot from '@/models/Slot.model';
import Color from 'color';
import ShipArrival from "@/models/ShipArrival.model";

const CalendarStore = namespace('calendar');

/**
 * Basis calendar item component which handles the user interaction. This component need to be used as a base for all
 * specific calendar items.
 */
@Component({})
export default class CalendarItemBaseComponent extends Vue {

  // -- Store Data
  // --- Global Store Data
  @CalendarStore.Getter(calendarStoreGetter.GLOBAL_HAS_SELECTION)
  private _globalHasSelection!: boolean;

  public get globalHasSelection(): boolean {
    return this._globalHasSelection;
  }

  @CalendarStore.Mutation(calendarStoreMutations.GLOBAL_SET_IS_MOUSE_DOWN)
  private setGlobalIsMouseDown!: (payload: boolean) => void;

  @CalendarStore.Getter(calendarStoreGetter.GLOBAL_IS_MOUSE_DOWN)
  private _globalIsMouseDown!: boolean;

  public get globalIsMouseDown(): boolean {
    return this._globalIsMouseDown;
  }

  @CalendarStore.Mutation(calendarStoreMutations.ADD_SELECTION_ITEM)
  private addSelectionItem!: (payload: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.CLEAR_SELECTION)
  private clearSelection!: () => void;

  @CalendarStore.Mutation(calendarStoreMutations.SET_SELECTION_REFERENCE)
  private setSelectionReference!: (payload: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.UPDATE_SELECTION)
  private updateSelection!: (payload: string) => void;

  // --- Item Store Data
  @CalendarStore.Getter(calendarStoreGetter.ITEM_IS_SELECTED)
  private _itemIsSelected!: (payload: string) => CalendarItemIsSelectedDefinition;

  public get itemIsSelected(): CalendarItemIsSelectedDefinition {
    return this._itemIsSelected(this.coordinate);
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_ITEM_FOCUSSED)
  private setItemFocusReference!: (payload?: string) => void;

  // -- Component Data
  /**
   * The data to work with
   */
  @Prop({default: null})
  public data!: Slot | null;

  /**
   * The coordinate of this cell in format x-y
   * @private
   */
  @Prop()
  private coordinate!: string;

  /**
   * Determines if an interaction is possible
   */
  @Prop({default: true})
  private noInteraction!: boolean;

  /**
   * Determines which background color is used
   */
  @Prop()
  public slotColor!: string;

  /**
   * Determines which opacity  is used
   */
  @Prop()
  public slotOpacity!: number;

  /**
   * Show stripes
   */
  @Prop()
  public slotStripes!: boolean;

  /**
   * Show if grouped
   */
  @Prop({default: false})
  public slotGrouped!: boolean;

  /**
   * Show if selected
   */
  @Prop({default: false})
  public slotSelected!: boolean;

  /**
   * Determines the width of the slot as factor for multiplication of standard slot width
   */
  @Prop({default: 1})
  private widthMultiply!: number;

  public get slotStyle(): any {
    // Default styles
    let styles: any = {
      width: `${CALENDAR_ITEM_WIDTH * this.widthMultiply}px`
    };

    // Specialized styles
    let background = this.slotColor ? `${this.slotColor} !important` : undefined;
    // Add Stripes if necessary
    if (this.slotStripes && this.slotColor) {
      const stripeColor = Color(this.slotColor).lighten(.5);
      background = `repeating-linear-gradient(45deg,${this.slotColor},${this.slotColor} 10px,${stripeColor} 10px,${stripeColor} 20px) !important`;
    }
    let boxShadow = undefined;

    if (this.slotGrouped) {
      boxShadow = `inset 0 0 0 5px  ${this.$vuetify.theme.currentTheme['calendar-item-grouped']}`
    }

    if (this.slotSelected) {
      boxShadow = `${boxShadow}, inset 0 0 0 8px blue !important`;

      if ((this.data?.booking.shipArrival as ShipArrival).hasRelevantChanges) {
        boxShadow = `${boxShadow}, inset 0 0 0 12px red !important`;
      }
    } else if ((this.data?.booking.shipArrival as ShipArrival).hasRelevantChanges) {
      boxShadow = `inset 0 0 0 6px red !important`;
    }

    styles = {
      ...styles, // default styles
      background,
      'box-shadow': boxShadow,
      'opacity': this.slotOpacity ? `${this.slotOpacity} !important` : undefined,
    }
    return styles;
  }

  // -- Event handler
  /**
   * Handles the mouse movement on the item
   */
  public onItemMouseMove() {
    if (!this.noInteraction) {
      // Handle moving over an item when dragging state is on
      if (this.globalIsMouseDown) {
        this.setItemFocusReference(this.coordinate);
        this.addSelectionItem(this.coordinate);
      }
    }
  }

  /**
   * Handles the mouse up event on the item
   */
  public onItemMouseUp() {
    if (!this.noInteraction) {
      this.setGlobalIsMouseDown(false);
    }
  }

  /**
   * Handles the mouse down event on the item
   * @param event
   */
  public onItemMouseDown(event: MouseEvent) {
    // Only admins are allowed to edit calendar data
    // 2 means right click
    if (event.buttons === 2 /* || !this.$hasRole(UserRoles.ADMIN) */ || this.noInteraction) {
      event.preventDefault();
      return;
    }
    // handle simple click if a selection already exists
    if (this.globalHasSelection) {
      this.clearSelection();
      this.setItemFocusReference(undefined);
    } else {
      // Handle simple item click
      this.addSelectionItem(this.coordinate);
      this.setItemFocusReference(this.coordinate);
      this.setGlobalIsMouseDown(true);
    }
  }
}
