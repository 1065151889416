/**
 * Bench value for vuetify virtual scroll element
 * For further information: https://vuetifyjs.com/ja/api/v-virtual-scroll/#bench
 */
export const LIST_BENCH_VALUE: number = 999; // use an unusual high number to prevent lagging
/**
 * Width of a single calendar cell.
 * ATTENTION: Hold this value synchrony with scss var $CALENDAR_ITEM_WIDTH
 */
export const CALENDAR_ITEM_WIDTH: number = 33;
/**
 * Height of a single calendar cell.
 * ATTENTION: Hold this value synchrony with scss var $CALENDAR_ITEM_HEIGHT
 */
export const CALENDAR_ITEM_HEIGHT: number = 150;
/**
 * Height of a single calendar cell.
 * ATTENTION: Hold this value synchrony with scss var $CALENDAR_ITEM_HEIGHT
 */
export const CALENDAR_DATE_ITEM_WIDTH: number = 150;
/**
 * Just some offset for fitting the calendar better in its pagecontext
 *ATTENTION: Hold this value synchrony with scss var $WEEK_NUMBER_CONTROLS
 */
export const WEEK_NUMBER_CONTROLS: number = 100;
