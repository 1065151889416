var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-item interact-item pa-1 d-flex flex-column justify-center align-center",class:{
          'no-interaction': _vm.noInteraction,
          'is-selected': _vm.itemIsSelected.isSelected,
          'is-grouped': _vm.slotGrouped,
          'selected-border-top': _vm.itemIsSelected.border.top,
          'selected-border-right': _vm.itemIsSelected.border.right,
          'selected-border-bottom': _vm.itemIsSelected.border.bottom,
          'selected-border-left': _vm.itemIsSelected.border.left
      },style:(Object.assign({}, _vm.slotStyle)),on:{"mousemove":_vm.onItemMouseMove,"mousedown":function($event){return _vm.onItemMouseDown($event)},"mouseup":_vm.onItemMouseUp}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }